import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKInputDocs from '@workday/canvas-kit-docs/dist/mdx/react/color-picker/color-input/ColorInput.mdx';
import CKPreviewDocs from '@workday/canvas-kit-docs/dist/mdx/react/color-picker/color-preview/ColorPreview.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/color-picker" storybook="https://workday.github.io/canvas-kit/?path=/story/components-inputs-color-picker-color-input--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19624-6826&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "color-input-anatomy"
      }}>{`Color Input Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/09358d70915e52262b002e665a946e28/536c7/component-anatomy-color-input.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "20%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Color Input in its default state.",
              "title": "Image of a Color Input in its default state.",
              "src": "/static/09358d70915e52262b002e665a946e28/50383/component-anatomy-color-input.png",
              "srcSet": ["/static/09358d70915e52262b002e665a946e28/1efb2/component-anatomy-color-input.png 370w", "/static/09358d70915e52262b002e665a946e28/50383/component-anatomy-color-input.png 740w", "/static/09358d70915e52262b002e665a946e28/536c7/component-anatomy-color-input.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Title of the input. Label position could be set to the top or left of the Color Input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Active Color Swatch:`}</strong>{` Square container that shows what the color looks like. Checkmark is
optional and could be set on when a valid hex value is entered in the text input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Text Input:`}</strong>{` Placeholder text of the Color Input is “#FFFFFF”. Placeholder text is replaced
when the user enters a different hex value.`}</li>
      </ol>
      <h2 {...{
        "id": "color-input-usage-guidance"
      }}>{`Color Input Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Color Input lets a user apply a specific color to an element.`}</li>
        <li parentName="ul">{`The user should enter a value in hexadecimal format (6 digits).`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Color Input to apply a custom color to an element such as text or background color using
hexadecimal numbers. This is commonly used for theming.`}</li>
      </ul>
      <CKInputDocs mdxType="CKInputDocs" />
      <h2 {...{
        "id": "color-preview-anatomy"
      }}>{`Color Preview Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6bb2aa949a90afa7c1b63978d1863af7/536c7/component-anatomy-color-preview.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "20%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Color Preview in a changed state.",
              "title": "Image of a Color Preview in a changed state.",
              "src": "/static/6bb2aa949a90afa7c1b63978d1863af7/50383/component-anatomy-color-preview.png",
              "srcSet": ["/static/6bb2aa949a90afa7c1b63978d1863af7/1efb2/component-anatomy-color-preview.png 370w", "/static/6bb2aa949a90afa7c1b63978d1863af7/50383/component-anatomy-color-preview.png 740w", "/static/6bb2aa949a90afa7c1b63978d1863af7/536c7/component-anatomy-color-preview.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Title of the input. Label position could be set to the top or left of the Color
Preview.`}</li>
        <li parentName="ol"><strong parentName="li">{`Active Color Swatch:`}</strong>{` Square container that shows what the color looks like.`}</li>
        <li parentName="ol"><strong parentName="li">{`Value:`}</strong>{` 6 digit hexadecimal that corresponds to the active color swatch.`}</li>
      </ol>
      <h2 {...{
        "id": "color-preview-usage-guidance"
      }}>{`Color Preview Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Color Preview is the read-only version of a Color Input`}</li>
        <li parentName="ul">{`It lets a user see what the color looks like in addition to displaying the hex value.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-1"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`When the color value of an element needs to be displayed, but should not be editable.`}</li>
      </ul>
      <CKPreviewDocs mdxType="CKPreviewDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Color Input and Color Preview must have visual labels that clearly indicate the purpose of the
input.`}</li>
        <li parentName="ul">{`When possible, error and alert states should provide information on how to fix issues or provide
hints on how to fix formatting.`}</li>
        <li parentName="ul">{`Do not use color alone to differentiate between errors and alerts. Although color is a great
visual indicator for users without disabilities, “Error” or “Alert” text should always be used to
distinguish errors and alerts on a page. Use icons as supplementary visual indicators for
different message states.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      